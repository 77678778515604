import React, { useState } from 'react'
import Loading from '../../components/Loading'
import NoItems from '../../components/NoItems'
import ListPosts from '../../pages/Posts/PostListPage'
// import { withRouter } from 'react-router-dom'


function List(props){

    const { posts, lines, mediaPostsServer1, history } = props


    const [ postsData , setPostsData ]  = useState( [] )
    const [ loading , setLoading ] = useState(true)
    const [ postSelected, setPostSelected ] = useState(new Set([]))
    const [ dataLines , setDataLines ] = useState([])
    


  React.useEffect(()=>{
    ( async ()=>{
      const response = await lines.get()
      if( response.status === 200 )
        setDataLines( response.data )
      else
        console.log('ocurrio un error al obtener los datos')
    })()
    
  },[])



  React.useEffect(()=>{
    

         ( async ()=>{

            const response = await posts.get();

            if( response.status === 200 ){
                setPostsData(response.data)
                setLoading(false)
            }else{
                console.log( 'Ocurrio un error al obtener los datos' )
            }
          } )()

    },[ posts ])



	
    const Badget = ({ children })=><span style={{
	    backgroundColor : 'black',
	    color : 'white',
	    fontSize : '8px',
	    padding : '5px',
	    borderRadius : '3px',
	    marginRight : '3px'
	    
	}}>{children}</span>

	    
	
	function showLines( lines ){



	    return dataLines.map((item,key) => {
		    
		if( lines.includes(`${item.value}`) ){
			
			return <Badget>{item.label}</Badget>
		}
      
      })
      
    }
    
    function goTo(uri, data){
        // history.push(uri)
        history.push({
            pathname: uri,
            state: { detail: data }
          })
    }



    function onPressHandler(item){
        
        if( !postSelected.has(item) ){
            postSelected.add(item)
            setPostSelected( new Set([ ...postSelected.values() ]))
            return
        }

        postSelected.delete(item)
        setPostSelected( new Set([ ...postSelected.values() ]))

    }


   
    if( loading === true )
        return <Loading />

    if( loading === false && postsData.length === 0 )
        return <NoItems />


    if( loading === false && postsData.length )
        return <ListPosts
            onPressHandler={onPressHandler}
            postsData={postsData} 
            mediaPostsServer1={ mediaPostsServer1 }
            postSelected={ postSelected }
            goTo={goTo}
            showLines={showLines}
            />


}


export default List