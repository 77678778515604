
    const mediaPostsServer1 = (uri) => {
        return `http://pdtclientsolutions.com:3020/${uri}`
    }

    const api_uri = (uri) => {
        return `http://pdtclientsolutions.com:3020/api/${uri}`
    }

    const crm_server = (uri)=>{
         return `https://pdtclientsolutions.com/crm-public/api/${uri}`
    }



export {
    api_uri,
    mediaPostsServer1,
    crm_server
    
}