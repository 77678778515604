import React,{useContext} from 'react';
import { Link } from 'react-router-dom'

// import useIsAuth from '../../Hooks/Auth'

import UserContext from '../../Contexts/UserContext'


 function NavBar(props){ 

	// const [ user, setUser ] = useIsAuth('user',);
	const user = useContext(UserContext);


	const { setUserDetails } = useContext(UserContext)

	function logout(){
		// localStorage.clear()
		setUserDetails({
			name: '',
			access_token : '',
			dateOfBirth: '',
			email: '',
			secretQuestion: '',
			secretAnswer: '',
		})

		localStorage.clear()

		window.location.href = '/'
	}
	
	if(user.access_token){

		
        return (
			<header>

				  <ul className="navbar-nav ml-auto">
		
		
					<li className="nav-item">
					  	<Link
					  		to="/"
					  		className="nav-item nav-link mr-4">
							Rates
						</Link>
					</li>		
			

					{
						(user.access_token)? 
						(<li className="nav-item">
						<Link
					  		to="/orders"
					  		className="nav-item nav-link mr-4">
							Operaciones
						</Link>
						</li>): ''
					}

{
						(user.access_token)? 
						(<li className="nav-item">
						<Link
					  		to="/balances"
					  		className="nav-item nav-link mr-4">
							Balances
						</Link>
						</li>): ''
					}

					{
						(!user.access_token)? 
						(<li className="nav-item">
						<Link
					  		to="/login"
					  		className="nav-link">
							Entrar
						</Link>
						</li>): ''
					}
					
					{
						(!user.access_token)? 
						(<li className="nav-item">
						<Link
					  		to="/register"
					  		className="nav-link btn btn-success text-white text-center">
							Crear cuenta
						</Link>
						</li>): ''
					}
					
					{
						(user.access_token)? 
						(<li className="nav-item">
							<Link
								to={'#'}
								onClick={()=>{logout()}}
								className="nav-link btn btn-danger text-white">
								Salir
							</Link>
						</li>) : ''
					}

				  </ul>			
	
		  </header>
	
		);
	}else{
		return ('')
	}
}

export default NavBar