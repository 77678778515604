import React from 'react'


function index(){

    return (
        <div className='f f-jcc f-aic container'>
        <span className='t1'>Cargando, por favor espere un momento. . .</span>
     </div>
    )
}

export default index