import React from 'react'
import Loading from '../../components/Loading'
import NoItems from '../../components/NoItems'
import PostUpdatePage from '../../pages/Posts/PostUpdatePage'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios'

function Update({ mediaPostsServer1, history, api_uri }){

    const [ postData, setPostData ] = React.useState(history.location.state.detail)
    const [ loading, setLoading ] = React.useState(false)
    const [ selected, setSelected] = React.useState([]);
    const [ fileToUpload, setFileToUpload ] = React.useState('')
        
    function goTo(uri){
        history.push(uri)
    }

    function onSubmitHandler(e){


		// if(selected.length === 0){
		// 	toast("Selecciona una linea!")
		// 	return
		// }

		if(postData === ''){
			toast("Falta el cuerpo del mensaje !")
			return
		}

		const lines = new Set([])
		selected.map((item,key)=>{

			lines.add(item.value)
			return true
		})


        const 	formData = new FormData();

        const tempData = {
            _id : postData._id,
            post : postData.post ,
        }

        console.log(tempData)
                formData.append( 'post', tempData.post  )
                formData.append( '_id',  tempData._id  )
				// formData.append( 'lines', [...lines] )

		if(fileToUpload){
			formData.append( 'fileToUpload', fileToUpload  )
		}

		console.log(fileToUpload)

		setLoading(true)

        console.log(postData)

		axios.post( api_uri(`update/post`), formData,{
            // onUploadProgress : progress,
        		headers: {
            	      'Content-Type': 'multipart/form-data',
          		}
		}).then((response)=>{
			setLoading(false)
			toast("Publicado con exito!")
		}).catch((error)=>{


			setLoading(false)
		})
	}
   
    if( loading === true )
        return <Loading />

    if( !loading === false && !postData)
        return <NoItems />


    if( loading === false && postData )
        return <PostUpdatePage
            postData={postData}
            setPostData={setPostData} 
            mediaPostsServer1={ mediaPostsServer1 }
            onSubmitHandler={onSubmitHandler}
            fileToUpload={fileToUpload}
            setFileToUpload={setFileToUpload}
            goTo={goTo}
            api_uri={ api_uri }
            />


}


export default withRouter(Update)