import React from 'react'

function index(){

    return (
        <div className='f f-jcc f-aic'>
            <span>Cargando, por favor espere un asdasd</span>
        </div>
    )
}

export default index