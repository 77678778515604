import React, { useState } from 'react'
import UserContext from './UserContext'
import SessionProvider from '../components/SessionProvider/Component'

const UserProvider = ({ children }) => {
  

  const [ userDetails, setUserDetails ] = useState({
    user_id : null,
    nombres: null,
    email: null,
    token: null,
    token_chat: null,
    type_user: null
})
 const ob = {
    ...userDetails,
    setUserDetails
  }

  return (
    <UserContext.Provider value={ob}>
      <SessionProvider />
      {children}
    </UserContext.Provider>
  )
}
export default UserProvider
