const handlers = ({ axios }) => ({

    get :   async (id) => await axios.get( `http://pdtclientsolutions.com:3020/api/get/posts` ),
    getById :   async (id) => await axios.get( `http://pdtclientsolutions.com:3020/api/get/posts/20` ),

    post:   async (data) => await axios.post( 'https://jsonplaceholder.typicode/posts', data ),

    put :   async (id, data) => {
                let response = await axios.put( `https://jsonplaceholder.typicode/posts/${id}`, data )
                return response
        },
        
    delete : async (id) => await axios.put( `https://jsonplaceholder.typicode/posts/${id}`),
})


export default handlers