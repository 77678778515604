import React, { useState } from 'react';
import axios from 'axios'
import API from '../../API/Auth';
import Gui from './Gui';



const Login = (props) => {

	const [ loading, set_Loading ] = useState(false);


	const [ inputsLogin, setInputsLogin ] = useState({
		email : '',
		password: '',
	 });



	function onChangeHandler(e){
		setInputsLogin({
			...inputsLogin,
			[e.target.name] : e.target.value,
		});
    }



	function onSubmitHandler(e){
		

		
		set_Loading(true)

		API.login({
			...inputsLogin
		  }).then(response => {
				
			console.log(response.data.token)
			axios.defaults.headers.common['access-token'] = response.data.access_token
			
			localStorage.setItem( 'user' , JSON.stringify({
				access_token : response.data.access_token
			}))

			set_Loading(false)

		  })
		.catch(error => {
				set_Loading(false)
		  });
	}
		

		return (<div>

			<Gui 
				params={{ ...inputsLogin }}
				onSubmitHandler={onSubmitHandler}
				onChangeHandler={onChangeHandler}
				loading={loading}
			/>
			</div>);
    
}

export default Login