import React from 'react';


function Index(props){

    return (
              <div className='card shadow1'>
				  
					<div className={'f f-jcc f-aic '}>
						<img src="./logo.png" alt="" style={{width : '200px', display : 'block'}} className='mt3 mb3'/>
					</div>

				  <div className="input-container ">
                      <label htmlFor="email" className='white-text'>Correo electronico</label>
                        <input
                        className="big-input" 
                        disabled={props.loading}
                        type="email" 
                        placeholder="example@email.ltd" 
                        onChange={props.onChangeHandler}
                        value={props.params.email || ''}
                        name='email'
                        id='email'
                        />
					</div>

					<div className="input-container">
						<label htmlFor="password" className={'white-text'}>Tu clave de acceso</label>
						<input
						disabled={props.loading}
						type="password" 
						className="big-input" 
						placeholder="*********"
						onChange={props.onChangeHandler}
						value={props.params.password || ''}
						name='password'
						id='password'/>
						</div>

						<button 
						className={'light-text primary-bg big-btn full-btn'}
						onClick={()=>props.onSubmitHandler()}
						disabled={props.loading}
						type="submit"
						>Entrar</button>

                </div>

    );
}

export default Index
