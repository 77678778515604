import React from 'react'

function index({ mediaPostsServer1 , postsData, postSelected, onPressHandler, goTo, showLines }){
  
    return (
        <div className='mt5'>
            <div className='container  f-aic ' style={{width : '650px', padding : '10px', background : 'white', borderRadius : '5px', marginTop : '20px', marginBottom : '20px'}}>

            <table className='table'>
                <thead>
                    <tr>
                        <td>#</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
            <tbody>
        {
            postsData.map((item,key)=>{
                
                return (
                    <tr key={ key } className={ (postSelected.has(item)) ? 'selected' : ''}  >
                        <td>{ key + 1 }</td>
                        <td onClick={ () => onPressHandler(item) }>
                            { ( postSelected.has(item)) && <input type="checkbox" checked /> }
                            { ( postSelected.has(item) === false ) && <input type="checkbox" /> }
                        </td>
                        <td> 
                            <div className='thumnail'>
                                {
                                    ( item.file ) && <img src={ mediaPostsServer1( item.file )  } alt={ item.title }  style={{ width : '100%', borderRadius : '10px' }} />
                                }
                            </div>
                        </td>
                        <td onClick={ () => goTo(`/post/update/${item._id}`, item) }>
                            <p className={'overflow double'} style={{ display:'flex', alignContent : 'center'}}>
                                { item.post } 
                            </p>
                              { showLines( item.lines ) }
                        </td>
                    </tr>
                )
            })
        }

        </tbody>
        </table>
        </div>
    </div>
    )


}



export default index