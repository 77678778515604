import React,{useContext, useState} from 'react';
import Login from '../components/Login/Component';
import NavBar from '../components/Navbar/Component';
import UserContext from '../Contexts/UserContext';
import MultiSelect from "react-multi-select-component";
import axios from 'axios'
import { AiOutlineSend, AiOutlinePaperClip,AiOutlineClose,AiTwotoneContainer, AiFillHome } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {crm_server, api_uri} from '../Env'

function LoginPage({history}) {
	
	const user = useContext(UserContext)
	const [ loading , set_Loading ] 	= 	useState(false)
	const [ post 	, setPost ] 		= 	useState('')
	const [ lines, setLines ] = useState([])
	const [ fileToUpload , setFileToUpload ] = useState('')
	const [ selected, setSelected] = useState([]);

	  React.useEffect(()=>{

		
		axios.get(crm_server(`lines/bussiness`)).then(response => {

			setLines(response.data)
			set_Loading(false)

		  })
		.catch(error => {
      console.log('Ocurrio un error al obtener los datos')
				set_Loading(false)
		  });

	  },[])




	function onSubmitHandler(e){


		if(selected.length === 0){
			toast("Selecciona una linea!")
			return
		}

		if(post === ''){
			toast("Falta el cuerpo del mensaje !")
			return
		}

		const lines = new Set([])
		selected.map((item,key)=>{

			lines.add(item.value)
			return true
		})


		const 	formData = new FormData();
				formData.append( 'post', post )
				formData.append( 'lines', [...lines] )

		if(fileToUpload){
			formData.append( 'fileToUpload', fileToUpload  )
		}

		console.log(fileToUpload)

		set_Loading(true)

		axios.post( api_uri(`create/post`), formData,{
            // onUploadProgress : progress,
        		headers: {
            	      'Content-Type': 'multipart/form-data',
          		}
		}).then((response)=>{

			setPost('')
			setFileToUpload('')
			setSelected([])
			set_Loading(false)
			toast("Publicado con exito!")
		}).catch((error)=>{


			set_Loading(false)
		})
	}



	if(user.access_token){
		return (
			<div>



				<div className="container f f-jcc f-aic">

				<div className='card shadow1'>
				  
				  <div className={'f f-jcc f-aic '}>
					  <img src="./logo.png" alt="" style={{width : '200px', display : 'block'}} className='mt3 mb3'/>
				  </div>

				  <div className="input-container ">
					<MultiSelect
						options={lines}
						value={selected}
						onChange={setSelected}
						labelledBy={"Select"}
						placeholder={'Para'}
					/>
				  </div>

				<div className="input-container ">
					<label htmlFor="email" className='white-text'>Mensaje</label>
					  <textarea
					  className="big-input"
					  disabled={loading}
					  placeholder=""
					  onChange={(e)=>setPost(e.target.value)}
					  value={post || ''}
					  name='post' cols='40' rows='8'></textarea>
				  </div>
			{(fileToUpload.name) &&

					<div className={'file-control'}>
						{fileToUpload.name}
						<span onClick={()=>setFileToUpload('')}><AiOutlineClose /></span>
					</div>
			}



			<button 
					  className={'light-text primary-bg big-btn round-btn f-jcc f-aic mr1'}
					  onClick={()=>history.push('/')}
					  type="button" >
					  	<AiFillHome />
					  </button>

					  <button 
					  className={'light-text primary-bg big-btn round-btn f-jcc f-aic mr1'}
					  onClick={()=>document.getElementById('fakefileinput').click()}
					  type="button" >
					  	<AiOutlinePaperClip />
					  </button>

					<button 
						className={'light-text secondary-bg big-btn round-btn f-jcc f-aic mr1'}
						onClick={()=>onSubmitHandler()}
						disabled={loading}
						type="button" >
							<AiOutlineSend />
					</button>

					<button 
						className={'light-text secondary-bg big-btn round-btn f-jcc f-aic '}
						onClick={()=>history.push('/posts')}
						disabled={loading}
						type="button" >
							<AiTwotoneContainer />
					</button>


					<input type={'file'} id='fakefileinput' style={{ display : 'none' }} onChange={(e)=>setFileToUpload( e.target.files[0] )} />


					

			</div>	
				</div>

				<ToastContainer />
			</div>
	);
	}

  return ( <div>
            	<NavBar/>
			<div className="container f f-jcc f-aic">
				<Login redirect={true}/>
			</div>
        </div> );
	}

export default LoginPage;
