import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import UserProvider from './Contexts//UserProvider'
import { ToastProvider } from 'react-toast-notifications'
import LoginPage from './pages/LoginPage'
import { mediaPostsServer1, api_uri } from './Env'
import axios from 'axios'
import PostListPage from './Hoc/Posts/List'
import PostUpdatePage from './Hoc/Posts/Update'
import { posts, lines } from './endpoints'


const postsHandlers = posts({ axios })
const linesHandlers = lines({ axios })

// basename='/app/'

function App(props) {



  return (
	<BrowserRouter>
		<Switch>
			<UserProvider>
					<ToastProvider>
						<Route exact path='/' render={(props) => <LoginPage {...props} />}/>
						<Route exact path='/posts' render={(props) => <PostListPage posts={ postsHandlers } lines={ linesHandlers } mediaPostsServer1={ mediaPostsServer1 } {...props} />}/>
						<Route exact path='/post/update/:_id' render={(props) => <PostUpdatePage 
						{...[postsHandlers, mediaPostsServer1]}
						api_uri={api_uri}
						{...props} 
						/>}/>
					</ToastProvider>
			</UserProvider>
		</Switch>
	</BrowserRouter>
  );
}

export default App;