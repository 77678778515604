import React from 'react';
import MultiSelect from "react-multi-select-component";

import { AiOutlineSend, AiOutlinePaperClip,AiOutlineClose,AiTwotoneContainer, AiFillHome } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {crm_server, api_uri} from '../../Env'

function index({loading, setFileToUpload, setPostData, postData, fileToUpload, onSubmitHandler, goTo }) {
	
		return (
			<div>
				<div className="container f f-jcc f-aic">

				<div className='card shadow1'>
				  
				  <div className={'f f-jcc f-aic '}>
					  <img src="../../logo.png" alt="" style={{width : '200px', display : 'block'}} className='mt3 mb3'/>
				  </div>

				<div className="input-container ">
					<label htmlFor="email" className='white-text'>Mensaje</label>
					  <textarea
					  className="big-input"
					  disabled={loading}
					  placeholder=""
					  onChange={(e)=>setPostData({ ...postData, post : e.target.value })}
					  value={postData.post || ''}
    name='post' cols='40' rows='8'></textarea>


				  </div>
			{(fileToUpload.name) &&

					<div className={'file-control'}>
						{fileToUpload.name}
						<span onClick={()=>setFileToUpload('')}><AiOutlineClose /></span>
					</div>
			}

            <button 
					  className={'light-text primary-bg big-btn round-btn f-jcc f-aic mr1'}
					  onClick={()=>goTo('/')}
					  type="button" >
					  	<AiFillHome />
					  </button>

					  <button 
					  className={'light-text primary-bg big-btn round-btn f-jcc f-aic mr1'}
					  onClick={()=>document.getElementById('fakefileinput').click()}
					  type="button"
            style={{
              background :'red',
              backgroundImage : `url(http://pdtclientsolutions.com:3020/${postData.file})`,
              backgroundPosition : 'center',
              backgroundSize : 'cover'
            }}
            >
					  	<AiOutlinePaperClip />
					  </button>

					<button 
						className={'light-text secondary-bg big-btn round-btn f-jcc f-aic mr1'}
						onClick={()=>onSubmitHandler()}
						disabled={loading}
						type="button" >
							<AiOutlineSend />
					</button>

					<button 
						className={'light-text secondary-bg big-btn round-btn f-jcc f-aic '}
						onClick={ ()=>goTo('/posts') }
						disabled={ loading }
						type="button" >
							<AiTwotoneContainer />
					</button>



                    <input type={'file'} id='fakefileinput' style={{ display : 'none' }} onChange={(e)=>setFileToUpload( e.target.files[0] )} />

					

			</div>	
				</div>

				<ToastContainer />
			</div>
	);
        }

export default index;
